import React from 'react';
import { Exposition } from '@brainstud/academy-api';
import { ActionMenu, Button, Status } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useTextExpander } from 'Modules/blended-learning-catalog-panel/Hooks';
import { ExpositionActions } from 'Modules/blended-learning-catalog-panel/Views/PortfolioView/ExpositionsView/ExpositionActions';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as DefaultIcon } from 'public/media/default_exposition.svg';
import { sanitizer } from 'Utils/Sanitizer';
import { useProfileProvider } from 'Views';
import styles from './ExpositionHeader.module.css';

const cx = classNames.bind(styles);

type Props = {
  exposition: Exposition;
};

/**
 * ExpositionHeader.
 *
 * Contains the Exposition header.
 */
export const ExpositionHeader = ({ exposition }: Props) => {
  const [t] = useTranslator();
  const { profile, isMine } = useProfileProvider();
  const [description, isExpanded, isExpandable, setIsExpanded] =
    useTextExpander(exposition?.description, 400);

  return (
    <div className={cx(styles.base)}>
      {exposition?.image ? (
        <img
          className={cx(styles.thumbnail)}
          src={exposition?.image}
          alt={exposition?.title}
        />
      ) : (
        <div className={cx(styles['default-icon'])}>
          <DefaultIcon />
        </div>
      )}
      <div className={cx(styles.vertical)}>
        <div className={cx(styles.title)}>
          <h2>{exposition?.title}</h2>
          {isMine && (
            <div>
              <ActionMenu outline id={exposition?.title}>
                <ExpositionActions exposition={exposition} />
              </ActionMenu>
            </div>
          )}
        </div>
        {profile && (
          <div>
            {`${t('student')}: `}
            <strong>{profile.account?.().fullName}</strong>
          </div>
        )}
        <div>
          <Status scheme="grey">{`${exposition.meta?.learningObjectCount || 0} ${t('assignments')}`}</Status>
          {(exposition.meta?.storedFilesCount ||
            exposition.meta?.storedFilesCount === 0) && (
            <Status scheme="grey">{`${exposition.meta?.storedFilesCount} ${t('files')}`}</Status>
          )}
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizer(`${description}${isExpanded ? '' : '...'}`),
          }}
        />
        {isExpandable && (
          <Button
            link
            type="button"
            onClick={() => setIsExpanded((prevExpanded) => !prevExpanded)}
          >
            {t(
              `views.portfolio.expositions.detail.${isExpanded ? 'hide' : 'show'}_description`
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
