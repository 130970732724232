import React, { useCallback } from 'react';
import { StoredFile } from '@brainstud/academy-api';
import { useStoredFile } from '@brainstud/academy-api/Hooks/useStoredFiles';
import { ActionMenu, ConfirmationModal } from '@brainstud/ui';
import { Delete, Edit } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useModals, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { StoredFileFormModal } from './StoredFileFormModal';
import styles from './StoredFileActionMenu.module.css';

const cx = classNames.bind(styles);

export const StoredFileActionMenu = ({
  storedFile,
}: {
  storedFile: StoredFile;
}) => {
  const [t] = useTranslator();
  const router = useRouter();
  const { expositionId } = router.query as { expositionId: string };
  const { openModal, closeModal } = useModals();
  const [setToast] = useToaster();
  const [{ destroy }] = useStoredFile(
    { storedFile: storedFile.id },
    { enabled: false }
  );

  const handleEdit = useCallback(
    (event) => {
      event.preventDefault();
      openModal(StoredFileFormModal, {
        storedFile,
      });
    },
    [openModal, storedFile]
  );

  const handleDelete = useCallback(
    (event) => {
      event.preventDefault();
      openModal(ConfirmationModal, {
        title: t('views.stored_file.delete.title'),
        description: t('views.stored_file.delete.description'),
        yes: t('confirm'),
        no: t('cancel'),
        isDanger: true,
        handleConfirm: async () => {
          await destroy.mutateAsync(
            {},
            {
              onSuccess: () => {
                setToast(t('views.stored_file.delete.success'), 'success');
                closeModal();
                if (router.pathname !== '/portfolio/expositions/[expositionId]')
                  router.replace(`/portfolio/expositions/${expositionId}`);
              },
              onError: () => {
                setToast(t('views.stored_file.delete.error'), 'error');
                closeModal();
              },
            }
          );
        },
      });
    },
    [closeModal, destroy, expositionId, openModal, router, setToast, t]
  );

  return (
    <div className={cx(styles.base)}>
      <ActionMenu id="stored-file-actions">
        <button role="menuitem" type="button" onClick={handleEdit}>
          <Edit />
          <span>{t('edit')}</span>
        </button>
        <button
          role="menuitem"
          type="button"
          onClick={handleDelete}
          className={cx(styles.destroy)}
        >
          <Delete />
          <span>{t('delete')}</span>
        </button>
      </ActionMenu>
    </div>
  );
};
