import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  StoredFile,
  StoredFileCreateInput,
  StoredFileDeleteInput,
  StoredFileFilters,
  StoredFileIncludes,
  StoredFileSort,
  StoredFileUpdateInput,
  UUID,
} from '../Types';

interface IStoredFileProps
  extends HookParams<
    typeof StoredFileIncludes,
    StoredFileFilters,
    typeof StoredFileSort
  > {
  storedFile?: UUID;
}

/**
 * Access to a single StoredFile.
 * @param storedFile
 * @param queryParameters
 * @param settings
 * @returns StoredFile
 */
export function useStoredFile(
  { storedFile, ...queryParameters }: IStoredFileProps = {},
  settings?: UseQueryOptions<DataDocument<StoredFile>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      enabled: !!storedFile,
      baseName: 'v1.stored_files',
      baseUri: '/v1/stored_files',
      uri: `/${storedFile}`,
      queryParameters,
      invalidate: ['v1.expositions', 'stored_files'],
    },
    context
  );
  return useSingleCRUD<
    StoredFile,
    StoredFileCreateInput,
    StoredFileUpdateInput,
    StoredFileDeleteInput
  >(request, settings);
}
